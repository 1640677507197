@import "./_variables.scss";

.App-viewarea {
    #pgStaffTravel {
        .btn-add-trip {
            margin: auto 0;
            white-space: nowrap;
        }

        hr {
            padding-top: 4px;
            border-bottom: 2px inset $secondary-color;
        }
    }

}