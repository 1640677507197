@import "./_variables.scss";

.App-title {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    color: #fff;

    h2 {
        margin: $margin-small-vertical 0 0;
        overflow: hidden;
        white-space: nowrap;
        line-height: $line-height-general;
        font-size: $font-size-h2;
        font-weight: $font-weight-bold;
        // height: #{$app-header-height - $margin-large-vertical -
        //   $margin-base-vertical};
    }

    h3 {
        margin: -3px 3px 0;
        font-size: $font-size-large-navbar;
        font-style: italic;
    }
}