@import "./_variables.scss";

.App-viewarea {
  .nav-btn-toolbar {
    .btn {
      display: inline-flex;
      margin: $margin-large-vertical $margin-small-horizontal $margin-medium-vertical;
    }

    &.btn-group-lg>.btn,
    .btn-lg {
      padding: .5rem 1rem;
      line-height: 1.2;
      // margin: $margin-large-vertical $margin-large-horizontal;
    }

    &.sect-toolbar {
      .btn {
        margin: $margin-small-vertical 0;
      }
    }
  }
}