@import "./_variables.scss";

.App-sidebar {
  display: block;
  min-width: $sidebar-width;
  max-width: $sidebar-width;
  // height: auto;
  // height: 100vh;
  align-items: stretch;
  padding: $padding-simple-vertical 0 $padding-large-vertical;
  // z-index: 999;
  background: $smoke-bg;
  color: $black-color;
  transition: all $general-transition-time;
  box-shadow: $box-shadow-crisp;
  border-bottom-right-radius: $border-radius-large;
  text-align: left;

  .nav {
    width: 100%;
  }

  .nav-item {
    width: 100%;
    display: flex;
    flex-wrap: wrap;

    &:hover {
      background-color: rgba(1, 65, 137, 0.1);
    }

    .nav-link {
      display: block;
      overflow-x: hidden;
      width: 100%;
      text-align: left;
      white-space: nowrap;

      &:hover {
        background-color: $secondary-bg;
        // font-weight: $font-weight-light;
        color: $white-color;
        box-shadow: $box-shadow-smooth;
        border-radius: 1px;
      }

      &.active {
        background-color: $secondary-bg;
        color: $white-color;
        box-shadow: $box-shadow-crisp;
        border-radius: 1px;
      }
    }

    .dropdown-toggle {
      display: inline-block;
      width: 100%;
      white-space: normal;
      text-align: left;
      cursor: pointer;
    }

    .nav-icon {
      font-size: $navbar-icon-size-lg;
      margin-right: $padding-icon-sm;
      padding-top: $padding-icon-sm;
      width: 28px;
      height: 24px;
    }

    .nav-item {
      padding-left: 1em;

      &:hover {
        background-color: inherit;
      }
    }
  }

  .nav-link {
    font-size: $font-size-medium;
    font-weight: $font-weight-normal;
    color: $dark-gray;
  }
}