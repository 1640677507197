@import "./_variables.scss";

.MuiDialog-paperWidthMd {
    width: 720px;
}

.App-viewarea {
    #pgStaffTourOfDuty {

        .MuiIconButton-root {
            color: $secondary-color;
        }

        .MuiCollapse-container {
            width: 100%;
        }

        .MuiCollapse-wrapper {
            width: 100%;
        }

        .row-control {
            margin-top: 16px;
        }

        @media (min-width: 992px) {
            .col-lg-6 {
                flex: 0 0 47%;
            }
        }
    }
}