@import "./_variables.scss";

$logo-width: 100px !default;

.App-header {
  // display: flex;
  // flex-wrap: nowrap;
  display: block;
  position: relative;
  height: $app-header-height;
  margin: auto;
  padding: $padding-simple-vertical $padding-simple-horizontal;
  background-color: $primary-color;
  box-shadow: $box-shadow-smooth;
  align-items: center;
  font-size: $font-size-h1;
  color: $white-color;

  .App-logo {
    position: absolute;
    top: 0;
    left: 0;
    width: #{$logo-width + $padding-base-horizontal * 2};
    height: $app-header-height;
    padding: $padding-small-vertical $padding-base-horizontal;
    text-align: left;

    img {
      max-width: $logo-width;
    }
  }

  .App-title {
    // width: calc(100vw - 120px);
    // width: calc(100vw - #{$padding-base-horizontal + 1});
    height: $app-header-height;
    padding: 0 0 0 (#{$logo-width + ($padding-base-horizontal * 2)});
    text-align: left;
  }
}