@import "./_variables.scss";

.App-viewarea {
    #pgStaffTravel {
        .MuiIconButton-root {
            color: $secondary-color;
        }

        .MuiCollapse-container {
            width: 100%;
        }

        .MuiCollapse-wrapper {
            width: 100%;
        }

        .row-control {
            margin-top: 16px;
        }

        @media (min-width: 1200px) {
            .col-xl-3 {
                flex: 0 0 24%;
            }
        }
    }
}