@import "./_variables.scss";

.App-viewarea {
  .form-header {
    margin: 0;
    background-color: $secondary-bg;
    color: $white-color;

    h3 {
      margin: 0;
      font-size: $font-size-h3;
      font-weight: $font-weight-semi;
    }

    h5 {
      margin: 0;
      font-size: $font-size-h5;
      font-weight: $font-weight-light;
      font-style: italic;
    }
  }
}