@import "./_variables.scss";

.App-viewarea {
  .sect-header {
    margin: $margin-small-vertical 0 $margin-xs-vertical;
    padding: $padding-small-vertical $padding-small-horizontal;
    line-height: $line-height-general;
    text-align: left;
    color: $white-color;
    font-size: $font-size-h4;
    font-weight: $font-weight-normal;
    background-color: $secondary-bg;
    border-radius: $border-radius-small;
  }
}