@import "./_variables.scss";

$splash-banner-height: 149px;

.App-viewarea {
    .App-splashPage {
        position: relative;
        top: 120px;
        margin: auto;
        width: 821px;

        &.jumbotron {
            padding: 0;
        }

        .splashBanner {
            position: relative;
            top: 0;
            border-radius: .3rem .3rem 0 0;
            height: $splash-banner-height;

            .img-bkgrd {
                position: relative;
                top: 0;
                left: 0;
                border-radius: .3rem .3rem 0 0;
            }

            .img-logo {
                position: absolute;
                top: 15px;
                left: 78px;
                width: 130px;
                z-index: 1;
            }
        }

        .App-title {
            position: absolute;
            top: 60px;
            height: #{$splash-banner-height - 60px};
        }

        .bodyText {
            padding: $padding-large-vertical $padding-large-horizontal;

            p {
                text-align: left;
            }
        }
    }
}