.App-title{position:absolute;top:0;left:0;width:100%;color:#fff}.App-title h2{margin:10px 0 0;overflow:hidden;white-space:nowrap;line-height:1.5;font-size:36px;font-weight:600}.App-title h3{margin:-3px 3px 0;font-size:20px;font-style:italic}

.App-header{display:block;position:relative;height:110px;margin:auto;padding:10px 18px;background-color:#1d3e75;box-shadow:0 0 10px 0 rgba(0,0,0,0.6);align-items:center;font-size:48px;color:#fff}.App-header .App-logo{position:absolute;top:0;left:0;width:132px;height:110px;padding:5px 16px;text-align:left}.App-header .App-logo img{max-width:100px}.App-header .App-title{height:110px;padding:0 0 0 132px;text-align:left}

.App-viewarea .nav-icon{font-size:20px;padding-right:4px}

.App-sidebar{display:block;min-width:255px;max-width:255px;align-items:stretch;padding:10px 0 24px;background:#f3f3f3;color:#212529;transition:all 300ms;box-shadow:3px 3px 3px rgba(51,51,51,0.5);border-bottom-right-radius:6px;text-align:left}.App-sidebar .nav{width:100%}.App-sidebar .nav-item{width:100%;display:flex;flex-wrap:wrap}.App-sidebar .nav-item:hover{background-color:rgba(1,65,137,0.1)}.App-sidebar .nav-item .nav-link{display:block;overflow-x:hidden;width:100%;text-align:left;white-space:nowrap}.App-sidebar .nav-item .nav-link:hover{background-color:#346ba9;color:#fff;box-shadow:0 0 10px 0 rgba(0,0,0,0.6);border-radius:1px}.App-sidebar .nav-item .nav-link.active{background-color:#346ba9;color:#fff;box-shadow:3px 3px 3px rgba(51,51,51,0.5);border-radius:1px}.App-sidebar .nav-item .dropdown-toggle{display:inline-block;width:100%;white-space:normal;text-align:left;cursor:pointer}.App-sidebar .nav-item .nav-icon{font-size:24px;margin-right:4px;padding-top:4px;width:28px;height:24px}.App-sidebar .nav-item .nav-item{padding-left:1em}.App-sidebar .nav-item .nav-item:hover{background-color:inherit}.App-sidebar .nav-link{font-size:16px;font-weight:400;color:#495057}

.App-footer{position:relative;text-align:center;bottom:0}

.App-viewarea .App-splashPage{position:relative;top:120px;margin:auto;width:821px}.App-viewarea .App-splashPage.jumbotron{padding:0}.App-viewarea .App-splashPage .splashBanner{position:relative;top:0;border-radius:.3rem .3rem 0 0;height:149px}.App-viewarea .App-splashPage .splashBanner .img-bkgrd{position:relative;top:0;left:0;border-radius:.3rem .3rem 0 0}.App-viewarea .App-splashPage .splashBanner .img-logo{position:absolute;top:15px;left:78px;width:130px;z-index:1}.App-viewarea .App-splashPage .App-title{position:absolute;top:60px;height:89px}.App-viewarea .App-splashPage .bodyText{padding:24px 30px}.App-viewarea .App-splashPage .bodyText p{text-align:left}

.App-viewarea .nav-btn-toolbar{display:block}

.App-viewarea .nav-btn-toolbar .btn{display:inline-flex;margin:24px 10px 15px}.App-viewarea .nav-btn-toolbar.btn-group-lg>.btn,.App-viewarea .nav-btn-toolbar .btn-lg{padding:.5rem 1rem;line-height:1.2}.App-viewarea .nav-btn-toolbar.sect-toolbar .btn{margin:10px 0}

.App-viewarea .form-header{margin:0;background-color:#346ba9;color:#fff}.App-viewarea .form-header h3{margin:0;font-size:26px;font-weight:500}.App-viewarea .form-header h5{margin:0;font-size:16px;font-weight:300;font-style:italic}

#emp-selector .MuiInputLabel-filled{color:#000}#emp-selector .MuiInputLabel-filled.MuiInputLabel-shrink{color:rgba(0,0,0,0.54)}

.MuiAutocomplete-popper [class*="selOffice-selValue"]{min-width:30px;max-width:30px}.MuiSelect-root [class*="selOffice-selLabel"]{display:none}#officeSelected label+.MuiInput-formControl{margin-top:16px}#officeSelected-label.altLeftOffset{left:-10px}

.MuiAutocomplete-popper [class*="callPrefix-selLabel"]{min-width:100px;max-width:100px}

.App-viewarea .sect-header{margin:10px 0 5px;padding:5px 10px;line-height:1.5;text-align:left;color:#fff;font-size:20px;font-weight:400;background-color:#346ba9;border-radius:3px}

#sectDependents .form-row{margin-left:0;margin-right:0}

.MuiAutocomplete-popper [class*="selCourse-selValue"]{min-width:110px;max-width:120px}.MuiSelect-root [class*="selCourse-selLabel"]{display:none}#course-selector label+.MuiInput-formControl{margin-top:14px}

#sectTraining .form-row{margin-left:0;margin-right:0}

#dependentsGrid{padding:0}#dependentsGrid .MuiTable-root{background-color:#f3f3f3}#dependentsGrid .MuiTableRow-root{white-space:nowrap}#dependentsGrid .MuiTableCell-head{background-color:rgba(0,0,0,0.09);color:rgba(0,0,0,0.54)}#dependentsGrid .MuiTableCell-body{color:rgba(0,0,0,0.54)}

.MuiAutocomplete-popper [class*="selJob-selValue"]{min-width:110px;max-width:120px}.MuiSelect-root [class*="selJob-selLabel"]{display:none}#job-selector label+.MuiInput-formControl{margin-top:14px}

.MuiDialog-paperWidthMd{width:720px}.App-viewarea #pgStaffTourOfDuty .MuiIconButton-root{color:#346ba9}.App-viewarea #pgStaffTourOfDuty .MuiCollapse-container{width:100%}.App-viewarea #pgStaffTourOfDuty .MuiCollapse-wrapper{width:100%}.App-viewarea #pgStaffTourOfDuty .row-control{margin-top:16px}@media (min-width: 992px){.App-viewarea #pgStaffTourOfDuty .col-lg-6{flex:0 0 47%}}

.App-viewarea #staffTourOfDuty .MuiIconButton-root{color:#346ba9}

.MuiMenuItem-root [class*="selTravel-selValue"]{min-width:20px;max-width:20px}.MuiSelect-root [class*="selTravel-selValue"]{display:none}#travel-selector label+.MuiInput-formControl{margin-top:14px}

.App-viewarea #pgStaffTravel .MuiIconButton-root{color:#346ba9}.App-viewarea #pgStaffTravel .MuiCollapse-container{width:100%}.App-viewarea #pgStaffTravel .MuiCollapse-wrapper{width:100%}.App-viewarea #pgStaffTravel .row-control{margin-top:16px}@media (min-width: 1200px){.App-viewarea #pgStaffTravel .col-xl-3{flex:0 0 24%}}

.App-viewarea #staffTravelGrid .MuiIconButton-root{color:#346ba9}

.App-viewarea #pgStaffTravel .btn-add-trip{margin:auto 0;white-space:nowrap}.App-viewarea #pgStaffTravel hr{padding-top:4px;border-bottom:2px inset #346ba9}

.MuiDialog-paperWidthMd{width:960px}.App-viewarea #pgStaffTDY .MuiIconButton-root{color:#346ba9}.App-viewarea #pgStaffTDY .MuiCollapse-container{width:100%}.App-viewarea #pgStaffTDY .MuiCollapse-wrapper{width:100%}.App-viewarea #pgStaffTDY .row-control{margin-top:16px}@media (min-width: 1200px){.App-viewarea #pgStaffTDY .col-xl-3{flex:0 0 24%}}

.App-viewarea #staffTDYGrid .MuiIconButton-root{color:#346ba9}

.App-viewarea #pgStaffTDY .btn-add-tdy{margin:auto 0;white-space:nowrap}.App-viewarea #pgStaffTDY hr{border-bottom:2px inset #346ba9}

.MuiAutocomplete-root[name="officeSelected"] .MuiAutocomplete-inputRoot[class*="MuiFilledInput-root"]{padding-left:0}.MuiAutocomplete-root[name="officeSelected"] .MuiAutocomplete-inputRoot[class*="MuiFilledInput-root"] #officeSelected{padding-left:2px}

/*      light colors         */
/*           Font Smoothing      */
body,
h1,
.h1,
h2,
.h2,
h3,
.h3,
h4,
.h4,
h5,
.h5,
h6,
.h6,
p,
.navbar,
.brand,
.btn-simple,
.alert,
a,
.td-name,
td,
button.close {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-weight: 400;
}

h1,
.h1,
h2,
.h2,
h3,
.h3,
h4,
.h4 {
  font-weight: 300;
  margin: 24px 0 15px;
}

h1,
.h1 {
  font-size: 48px;
}

h2,
.h2 {
  font-size: 36px;
}

h3,
.h3 {
  font-size: 26px;
  margin: 20px 0 10px;
}

h4,
.h4 {
  font-size: 20px;
  line-height: 30px;
}

h5,
.h5 {
  font-size: 16px;
  margin-bottom: 15px;
}

h6,
.h6 {
  font-size: 14px;
  font-weight: 600;
  text-transform: uppercase;
}

p {
  font-size: 16px;
  line-height: 1.5;
}

h1 small,
h2 small,
h3 small,
h4 small,
h5 small,
h6 small,
.h1 small,
.h2 small,
.h3 small,
.h4 small,
.h5 small,
.h6 small,
h1 .small,
h2 .small,
h3 .small,
h4 .small,
h5 .small,
h6 .small,
.h1 .small,
.h2 .small,
.h3 .small,
.h4 .small,
.h5 .small,
.h6 .small {
  color: #495057;
  font-weight: 300;
  line-height: 1.5;
}

h1 small,
h2 small,
h3 small,
h1 .small,
h2 .small,
h3 .small {
  font-size: 60%;
}

h1 .subtitle {
  display: block;
  margin: 0 0 24px;
}

.text-muted {
  color: #495057 !important;
}

.text-primary,
.text-primary:hover {
  color: #1d3e75 !important;
}

.text-info,
.text-info:hover {
  color: #1dc7ea !important;
}

.text-success,
.text-success:hover {
  color: #87cb16 !important;
}

.text-warning,
.text-warning:hover {
  color: #ff9500 !important;
}

.text-danger,
.text-danger:hover {
  color: #ff4a55 !important;
}

.App-viewarea .btn {
  border-width: 2px;
  background-color: transparent;
  font-weight: 400;
  opacity: 0.8;
  filter: alpha(opacity=80);
  border-color: #888888;
  color: #888888;
}

.App-viewarea .btn:hover, .App-viewarea .btn:focus, .App-viewarea .btn:active, .App-viewarea .btn.active,
.open > .App-viewarea .btn.dropdown-toggle {
  background-color: transparent;
  color: #777777;
  border-color: #777777;
}

.App-viewarea .btn.disabled, .App-viewarea .btn.disabled:hover, .App-viewarea .btn.disabled:focus, .App-viewarea .btn.disabled.focus, .App-viewarea .btn.disabled:active, .App-viewarea .btn.disabled.active, .App-viewarea .btn:disabled, .App-viewarea .btn:disabled:hover, .App-viewarea .btn:disabled:focus, .App-viewarea .btn:disabled.focus, .App-viewarea .btn:disabled:active, .App-viewarea .btn:disabled.active, .App-viewarea .btn[disabled], .App-viewarea .btn[disabled]:hover, .App-viewarea .btn[disabled]:focus, .App-viewarea .btn[disabled].focus, .App-viewarea .btn[disabled]:active, .App-viewarea .btn[disabled].active,
fieldset[disabled] .App-viewarea .btn,
fieldset[disabled] .App-viewarea .btn:hover,
fieldset[disabled] .App-viewarea .btn:focus,
fieldset[disabled] .App-viewarea .btn.focus,
fieldset[disabled] .App-viewarea .btn:active,
fieldset[disabled] .App-viewarea .btn.active {
  background-color: transparent;
  border-color: #888888;
}

.App-viewarea .btn.btn-fill {
  color: #ffffff;
  background-color: #888888;
  opacity: 1;
  filter: alpha(opacity=100);
}

.App-viewarea .btn.btn-fill:hover, .App-viewarea .btn.btn-fill:focus, .App-viewarea .btn.btn-fill:active, .App-viewarea .btn.btn-fill.active,
.open > .App-viewarea .btn.btn-fill.dropdown-toggle {
  background-color: #777777;
  color: #ffffff;
}

.App-viewarea .btn.btn-fill .caret {
  border-top-color: #ffffff;
}

.App-viewarea .btn .caret {
  border-top-color: #888888;
}

.App-viewarea .btn:hover, .App-viewarea .btn:focus {
  opacity: 1;
  filter: alpha(opacity=100);
  outline: 0 !important;
}

.App-viewarea .btn:active, .App-viewarea .btn.active,
.open > .App-viewarea .btn.dropdown-toggle {
  box-shadow: none;
  outline: 0 !important;
}

.App-viewarea .btn.btn-icon {
  padding: 8px;
}

.App-viewarea .btn-default {
  border-color: #888888;
  color: #888888;
}

.App-viewarea .btn-default:hover, .App-viewarea .btn-default:focus, .App-viewarea .btn-default:active, .App-viewarea .btn-default.active,
.open > .App-viewarea .btn-default.dropdown-toggle {
  background-color: transparent;
  color: #777777;
  border-color: #777777;
}

.App-viewarea .btn-default.disabled, .App-viewarea .btn-default.disabled:hover, .App-viewarea .btn-default.disabled:focus, .App-viewarea .btn-default.disabled.focus, .App-viewarea .btn-default.disabled:active, .App-viewarea .btn-default.disabled.active, .App-viewarea .btn-default:disabled, .App-viewarea .btn-default:disabled:hover, .App-viewarea .btn-default:disabled:focus, .App-viewarea .btn-default:disabled.focus, .App-viewarea .btn-default:disabled:active, .App-viewarea .btn-default:disabled.active, .App-viewarea .btn-default[disabled], .App-viewarea .btn-default[disabled]:hover, .App-viewarea .btn-default[disabled]:focus, .App-viewarea .btn-default[disabled].focus, .App-viewarea .btn-default[disabled]:active, .App-viewarea .btn-default[disabled].active,
fieldset[disabled] .App-viewarea .btn-default,
fieldset[disabled] .App-viewarea .btn-default:hover,
fieldset[disabled] .App-viewarea .btn-default:focus,
fieldset[disabled] .App-viewarea .btn-default.focus,
fieldset[disabled] .App-viewarea .btn-default:active,
fieldset[disabled] .App-viewarea .btn-default.active {
  background-color: transparent;
  border-color: #888888;
}

.App-viewarea .btn-default.btn-fill {
  color: #ffffff;
  background-color: #888888;
  opacity: 1;
  filter: alpha(opacity=100);
}

.App-viewarea .btn-default.btn-fill:hover, .App-viewarea .btn-default.btn-fill:focus, .App-viewarea .btn-default.btn-fill:active, .App-viewarea .btn-default.btn-fill.active,
.open > .App-viewarea .btn-default.btn-fill.dropdown-toggle {
  background-color: #777777;
  color: #ffffff;
}

.App-viewarea .btn-default.btn-fill .caret {
  border-top-color: #ffffff;
}

.App-viewarea .btn-default .caret {
  border-top-color: #888888;
}

.App-viewarea .btn-primary {
  border-color: #1d3e75;
  color: #1d3e75;
}

.App-viewarea .btn-primary:hover, .App-viewarea .btn-primary:focus, .App-viewarea .btn-primary:active, .App-viewarea .btn-primary.active,
.open > .App-viewarea .btn-primary.dropdown-toggle {
  background-color: transparent;
  color: #346ba9;
  border-color: #346ba9;
}

.App-viewarea .btn-primary.disabled, .App-viewarea .btn-primary.disabled:hover, .App-viewarea .btn-primary.disabled:focus, .App-viewarea .btn-primary.disabled.focus, .App-viewarea .btn-primary.disabled:active, .App-viewarea .btn-primary.disabled.active, .App-viewarea .btn-primary:disabled, .App-viewarea .btn-primary:disabled:hover, .App-viewarea .btn-primary:disabled:focus, .App-viewarea .btn-primary:disabled.focus, .App-viewarea .btn-primary:disabled:active, .App-viewarea .btn-primary:disabled.active, .App-viewarea .btn-primary[disabled], .App-viewarea .btn-primary[disabled]:hover, .App-viewarea .btn-primary[disabled]:focus, .App-viewarea .btn-primary[disabled].focus, .App-viewarea .btn-primary[disabled]:active, .App-viewarea .btn-primary[disabled].active,
fieldset[disabled] .App-viewarea .btn-primary,
fieldset[disabled] .App-viewarea .btn-primary:hover,
fieldset[disabled] .App-viewarea .btn-primary:focus,
fieldset[disabled] .App-viewarea .btn-primary.focus,
fieldset[disabled] .App-viewarea .btn-primary:active,
fieldset[disabled] .App-viewarea .btn-primary.active {
  background-color: transparent;
  border-color: #1d3e75;
}

.App-viewarea .btn-primary.btn-fill {
  color: #ffffff;
  background-color: #1d3e75;
  opacity: 1;
  filter: alpha(opacity=100);
}

.App-viewarea .btn-primary.btn-fill:hover, .App-viewarea .btn-primary.btn-fill:focus, .App-viewarea .btn-primary.btn-fill:active, .App-viewarea .btn-primary.btn-fill.active,
.open > .App-viewarea .btn-primary.btn-fill.dropdown-toggle {
  background-color: #346ba9;
  color: #ffffff;
}

.App-viewarea .btn-primary.btn-fill .caret {
  border-top-color: #ffffff;
}

.App-viewarea .btn-primary .caret {
  border-top-color: #1d3e75;
}

.App-viewarea .btn-secondary {
  border-color: #346ba9;
  color: #346ba9;
}

.App-viewarea .btn-secondary:hover, .App-viewarea .btn-secondary:focus, .App-viewarea .btn-secondary:active, .App-viewarea .btn-secondary.active,
.open > .App-viewarea .btn-secondary.dropdown-toggle {
  background-color: transparent;
  color: #23538a;
  border-color: #23538a;
}

.App-viewarea .btn-secondary.disabled, .App-viewarea .btn-secondary.disabled:hover, .App-viewarea .btn-secondary.disabled:focus, .App-viewarea .btn-secondary.disabled.focus, .App-viewarea .btn-secondary.disabled:active, .App-viewarea .btn-secondary.disabled.active, .App-viewarea .btn-secondary:disabled, .App-viewarea .btn-secondary:disabled:hover, .App-viewarea .btn-secondary:disabled:focus, .App-viewarea .btn-secondary:disabled.focus, .App-viewarea .btn-secondary:disabled:active, .App-viewarea .btn-secondary:disabled.active, .App-viewarea .btn-secondary[disabled], .App-viewarea .btn-secondary[disabled]:hover, .App-viewarea .btn-secondary[disabled]:focus, .App-viewarea .btn-secondary[disabled].focus, .App-viewarea .btn-secondary[disabled]:active, .App-viewarea .btn-secondary[disabled].active,
fieldset[disabled] .App-viewarea .btn-secondary,
fieldset[disabled] .App-viewarea .btn-secondary:hover,
fieldset[disabled] .App-viewarea .btn-secondary:focus,
fieldset[disabled] .App-viewarea .btn-secondary.focus,
fieldset[disabled] .App-viewarea .btn-secondary:active,
fieldset[disabled] .App-viewarea .btn-secondary.active {
  background-color: transparent;
  border-color: #346ba9;
}

.App-viewarea .btn-secondary.btn-fill {
  color: #ffffff;
  background-color: #346ba9;
  opacity: 1;
  filter: alpha(opacity=100);
}

.App-viewarea .btn-secondary.btn-fill:hover, .App-viewarea .btn-secondary.btn-fill:focus, .App-viewarea .btn-secondary.btn-fill:active, .App-viewarea .btn-secondary.btn-fill.active,
.open > .App-viewarea .btn-secondary.btn-fill.dropdown-toggle {
  background-color: #23538a;
  color: #ffffff;
}

.App-viewarea .btn-secondary.btn-fill .caret {
  border-top-color: #ffffff;
}

.App-viewarea .btn-secondary .caret {
  border-top-color: #346ba9;
}

.App-viewarea .btn-success {
  border-color: #87cb16;
  color: #87cb16;
}

.App-viewarea .btn-success:hover, .App-viewarea .btn-success:focus, .App-viewarea .btn-success:active, .App-viewarea .btn-success.active,
.open > .App-viewarea .btn-success.dropdown-toggle {
  background-color: transparent;
  color: #049f0c;
  border-color: #049f0c;
}

.App-viewarea .btn-success.disabled, .App-viewarea .btn-success.disabled:hover, .App-viewarea .btn-success.disabled:focus, .App-viewarea .btn-success.disabled.focus, .App-viewarea .btn-success.disabled:active, .App-viewarea .btn-success.disabled.active, .App-viewarea .btn-success:disabled, .App-viewarea .btn-success:disabled:hover, .App-viewarea .btn-success:disabled:focus, .App-viewarea .btn-success:disabled.focus, .App-viewarea .btn-success:disabled:active, .App-viewarea .btn-success:disabled.active, .App-viewarea .btn-success[disabled], .App-viewarea .btn-success[disabled]:hover, .App-viewarea .btn-success[disabled]:focus, .App-viewarea .btn-success[disabled].focus, .App-viewarea .btn-success[disabled]:active, .App-viewarea .btn-success[disabled].active,
fieldset[disabled] .App-viewarea .btn-success,
fieldset[disabled] .App-viewarea .btn-success:hover,
fieldset[disabled] .App-viewarea .btn-success:focus,
fieldset[disabled] .App-viewarea .btn-success.focus,
fieldset[disabled] .App-viewarea .btn-success:active,
fieldset[disabled] .App-viewarea .btn-success.active {
  background-color: transparent;
  border-color: #87cb16;
}

.App-viewarea .btn-success.btn-fill {
  color: #ffffff;
  background-color: #87cb16;
  opacity: 1;
  filter: alpha(opacity=100);
}

.App-viewarea .btn-success.btn-fill:hover, .App-viewarea .btn-success.btn-fill:focus, .App-viewarea .btn-success.btn-fill:active, .App-viewarea .btn-success.btn-fill.active,
.open > .App-viewarea .btn-success.btn-fill.dropdown-toggle {
  background-color: #049f0c;
  color: #ffffff;
}

.App-viewarea .btn-success.btn-fill .caret {
  border-top-color: #ffffff;
}

.App-viewarea .btn-success .caret {
  border-top-color: #87cb16;
}

.App-viewarea .btn-info {
  border-color: #1dc7ea;
  color: #1dc7ea;
}

.App-viewarea .btn-info:hover, .App-viewarea .btn-info:focus, .App-viewarea .btn-info:active, .App-viewarea .btn-info.active,
.open > .App-viewarea .btn-info.dropdown-toggle {
  background-color: transparent;
  color: #42d0ed;
  border-color: #42d0ed;
}

.App-viewarea .btn-info.disabled, .App-viewarea .btn-info.disabled:hover, .App-viewarea .btn-info.disabled:focus, .App-viewarea .btn-info.disabled.focus, .App-viewarea .btn-info.disabled:active, .App-viewarea .btn-info.disabled.active, .App-viewarea .btn-info:disabled, .App-viewarea .btn-info:disabled:hover, .App-viewarea .btn-info:disabled:focus, .App-viewarea .btn-info:disabled.focus, .App-viewarea .btn-info:disabled:active, .App-viewarea .btn-info:disabled.active, .App-viewarea .btn-info[disabled], .App-viewarea .btn-info[disabled]:hover, .App-viewarea .btn-info[disabled]:focus, .App-viewarea .btn-info[disabled].focus, .App-viewarea .btn-info[disabled]:active, .App-viewarea .btn-info[disabled].active,
fieldset[disabled] .App-viewarea .btn-info,
fieldset[disabled] .App-viewarea .btn-info:hover,
fieldset[disabled] .App-viewarea .btn-info:focus,
fieldset[disabled] .App-viewarea .btn-info.focus,
fieldset[disabled] .App-viewarea .btn-info:active,
fieldset[disabled] .App-viewarea .btn-info.active {
  background-color: transparent;
  border-color: #1dc7ea;
}

.App-viewarea .btn-info.btn-fill {
  color: #ffffff;
  background-color: #1dc7ea;
  opacity: 1;
  filter: alpha(opacity=100);
}

.App-viewarea .btn-info.btn-fill:hover, .App-viewarea .btn-info.btn-fill:focus, .App-viewarea .btn-info.btn-fill:active, .App-viewarea .btn-info.btn-fill.active,
.open > .App-viewarea .btn-info.btn-fill.dropdown-toggle {
  background-color: #42d0ed;
  color: #ffffff;
}

.App-viewarea .btn-info.btn-fill .caret {
  border-top-color: #ffffff;
}

.App-viewarea .btn-info .caret {
  border-top-color: #1dc7ea;
}

.App-viewarea .btn-warning {
  border-color: #ff9500;
  color: #ff9500;
}

.App-viewarea .btn-warning:hover, .App-viewarea .btn-warning:focus, .App-viewarea .btn-warning:active, .App-viewarea .btn-warning.active,
.open > .App-viewarea .btn-warning.dropdown-toggle {
  background-color: transparent;
  color: #ed8d00;
  border-color: #ed8d00;
}

.App-viewarea .btn-warning.disabled, .App-viewarea .btn-warning.disabled:hover, .App-viewarea .btn-warning.disabled:focus, .App-viewarea .btn-warning.disabled.focus, .App-viewarea .btn-warning.disabled:active, .App-viewarea .btn-warning.disabled.active, .App-viewarea .btn-warning:disabled, .App-viewarea .btn-warning:disabled:hover, .App-viewarea .btn-warning:disabled:focus, .App-viewarea .btn-warning:disabled.focus, .App-viewarea .btn-warning:disabled:active, .App-viewarea .btn-warning:disabled.active, .App-viewarea .btn-warning[disabled], .App-viewarea .btn-warning[disabled]:hover, .App-viewarea .btn-warning[disabled]:focus, .App-viewarea .btn-warning[disabled].focus, .App-viewarea .btn-warning[disabled]:active, .App-viewarea .btn-warning[disabled].active,
fieldset[disabled] .App-viewarea .btn-warning,
fieldset[disabled] .App-viewarea .btn-warning:hover,
fieldset[disabled] .App-viewarea .btn-warning:focus,
fieldset[disabled] .App-viewarea .btn-warning.focus,
fieldset[disabled] .App-viewarea .btn-warning:active,
fieldset[disabled] .App-viewarea .btn-warning.active {
  background-color: transparent;
  border-color: #ff9500;
}

.App-viewarea .btn-warning.btn-fill {
  color: #ffffff;
  background-color: #ff9500;
  opacity: 1;
  filter: alpha(opacity=100);
}

.App-viewarea .btn-warning.btn-fill:hover, .App-viewarea .btn-warning.btn-fill:focus, .App-viewarea .btn-warning.btn-fill:active, .App-viewarea .btn-warning.btn-fill.active,
.open > .App-viewarea .btn-warning.btn-fill.dropdown-toggle {
  background-color: #ed8d00;
  color: #ffffff;
}

.App-viewarea .btn-warning.btn-fill .caret {
  border-top-color: #ffffff;
}

.App-viewarea .btn-warning .caret {
  border-top-color: #ff9500;
}

.App-viewarea .btn-danger {
  border-color: #ff4a55;
  color: #ff4a55;
}

.App-viewarea .btn-danger:hover, .App-viewarea .btn-danger:focus, .App-viewarea .btn-danger:active, .App-viewarea .btn-danger.active,
.open > .App-viewarea .btn-danger.dropdown-toggle {
  background-color: transparent;
  color: #ee2d20;
  border-color: #ee2d20;
}

.App-viewarea .btn-danger.disabled, .App-viewarea .btn-danger.disabled:hover, .App-viewarea .btn-danger.disabled:focus, .App-viewarea .btn-danger.disabled.focus, .App-viewarea .btn-danger.disabled:active, .App-viewarea .btn-danger.disabled.active, .App-viewarea .btn-danger:disabled, .App-viewarea .btn-danger:disabled:hover, .App-viewarea .btn-danger:disabled:focus, .App-viewarea .btn-danger:disabled.focus, .App-viewarea .btn-danger:disabled:active, .App-viewarea .btn-danger:disabled.active, .App-viewarea .btn-danger[disabled], .App-viewarea .btn-danger[disabled]:hover, .App-viewarea .btn-danger[disabled]:focus, .App-viewarea .btn-danger[disabled].focus, .App-viewarea .btn-danger[disabled]:active, .App-viewarea .btn-danger[disabled].active,
fieldset[disabled] .App-viewarea .btn-danger,
fieldset[disabled] .App-viewarea .btn-danger:hover,
fieldset[disabled] .App-viewarea .btn-danger:focus,
fieldset[disabled] .App-viewarea .btn-danger.focus,
fieldset[disabled] .App-viewarea .btn-danger:active,
fieldset[disabled] .App-viewarea .btn-danger.active {
  background-color: transparent;
  border-color: #ff4a55;
}

.App-viewarea .btn-danger.btn-fill {
  color: #ffffff;
  background-color: #ff4a55;
  opacity: 1;
  filter: alpha(opacity=100);
}

.App-viewarea .btn-danger.btn-fill:hover, .App-viewarea .btn-danger.btn-fill:focus, .App-viewarea .btn-danger.btn-fill:active, .App-viewarea .btn-danger.btn-fill.active,
.open > .App-viewarea .btn-danger.btn-fill.dropdown-toggle {
  background-color: #ee2d20;
  color: #ffffff;
}

.App-viewarea .btn-danger.btn-fill .caret {
  border-top-color: #ffffff;
}

.App-viewarea .btn-danger .caret {
  border-top-color: #ff4a55;
}

.App-viewarea .btn-neutral {
  border-color: #ffffff;
  color: #ffffff;
}

.App-viewarea .btn-neutral:hover, .App-viewarea .btn-neutral:focus, .App-viewarea .btn-neutral:active, .App-viewarea .btn-neutral.active,
.open > .App-viewarea .btn-neutral.dropdown-toggle {
  background-color: transparent;
  color: #ffffff;
  border-color: #ffffff;
}

.App-viewarea .btn-neutral.disabled, .App-viewarea .btn-neutral.disabled:hover, .App-viewarea .btn-neutral.disabled:focus, .App-viewarea .btn-neutral.disabled.focus, .App-viewarea .btn-neutral.disabled:active, .App-viewarea .btn-neutral.disabled.active, .App-viewarea .btn-neutral:disabled, .App-viewarea .btn-neutral:disabled:hover, .App-viewarea .btn-neutral:disabled:focus, .App-viewarea .btn-neutral:disabled.focus, .App-viewarea .btn-neutral:disabled:active, .App-viewarea .btn-neutral:disabled.active, .App-viewarea .btn-neutral[disabled], .App-viewarea .btn-neutral[disabled]:hover, .App-viewarea .btn-neutral[disabled]:focus, .App-viewarea .btn-neutral[disabled].focus, .App-viewarea .btn-neutral[disabled]:active, .App-viewarea .btn-neutral[disabled].active,
fieldset[disabled] .App-viewarea .btn-neutral,
fieldset[disabled] .App-viewarea .btn-neutral:hover,
fieldset[disabled] .App-viewarea .btn-neutral:focus,
fieldset[disabled] .App-viewarea .btn-neutral.focus,
fieldset[disabled] .App-viewarea .btn-neutral:active,
fieldset[disabled] .App-viewarea .btn-neutral.active {
  background-color: transparent;
  border-color: #ffffff;
}

.App-viewarea .btn-neutral.btn-fill {
  color: #ffffff;
  background-color: #ffffff;
  opacity: 1;
  filter: alpha(opacity=100);
}

.App-viewarea .btn-neutral.btn-fill:hover, .App-viewarea .btn-neutral.btn-fill:focus, .App-viewarea .btn-neutral.btn-fill:active, .App-viewarea .btn-neutral.btn-fill.active,
.open > .App-viewarea .btn-neutral.btn-fill.dropdown-toggle {
  background-color: #ffffff;
  color: #ffffff;
}

.App-viewarea .btn-neutral.btn-fill .caret {
  border-top-color: #ffffff;
}

.App-viewarea .btn-neutral .caret {
  border-top-color: #ffffff;
}

.App-viewarea .btn-neutral:active, .App-viewarea .btn-neutral.active,
.open > .App-viewarea .btn-neutral.dropdown-toggle {
  background-color: #ffffff;
  color: #888888;
}

.App-viewarea .btn-neutral.btn-fill, .App-viewarea .btn-neutral.btn-fill:hover, .App-viewarea .btn-neutral.btn-fill:focus {
  color: #888888;
}

.App-viewarea .btn-neutral.btn-simple:active, .App-viewarea .btn-neutral.btn-simple.active {
  background-color: transparent;
}

.App-viewarea .btn:disabled, .App-viewarea .btn[disabled], .App-viewarea .btn.disabled {
  opacity: 0.5;
  filter: alpha(opacity=50);
}

.App-viewarea .btn-round {
  border-width: 1px;
  border-radius: 30px !important;
  padding: 9px 18px;
}

.App-viewarea .btn-round.btn-icon {
  padding: 9px;
}

.App-viewarea .btn-simple {
  border: 0;
  font-size: 16px;
  padding: 8px 16px;
}

.App-viewarea .btn-simple.btn-icon {
  padding: 8px;
}

.App-viewarea .btn-lg {
  font-size: 18px;
  border-radius: 6px;
  padding: 24px 30px;
  font-weight: 400;
}

.App-viewarea .btn-lg.btn-round {
  padding: 25px 30px;
}

.App-viewarea .btn-lg.btn-simple {
  padding: 26px 30px;
}

.App-viewarea .btn-sm {
  font-size: 12px;
  border-radius: 3px;
  padding: 5px 10px;
}

.App-viewarea .btn-sm.btn-round {
  padding: 6px 10px;
}

.App-viewarea .btn-sm.btn-simple {
  padding: 7px 10px;
}

.App-viewarea .btn-xs {
  font-size: 12px;
  border-radius: 3px;
  padding: 1px 5px;
}

.App-viewarea .btn-xs.btn-round {
  padding: 2px 5px;
}

.App-viewarea .btn-xs.btn-simple {
  padding: 3px 5px;
}

.App-viewarea .btn-wd {
  min-width: 140px;
}

.App-viewarea .btn-group.select {
  width: 100%;
}

.App-viewarea .btn-group.select .btn {
  text-align: left;
}

.App-viewarea .btn-group.select .caret {
  position: absolute;
  top: 50%;
  margin-top: -1px;
  right: 8px;
}

.MuiInputBase-input {
  font-size: 14px;
}

.MuiRadio-colorPrimary.Mui-checked {
  color: #1d3e75;
}

.MuiIconButton-colorPrimary {
  color: #1d3e75;
}

.MuiIconButton-colorPrimary {
  color: #1d3e75;
}

.MuiCheckbox-colorPrimary.Mui-checked {
  color: #1d3e75;
}

.MuiFormControl-root {
  width: 100%;
  text-align: left;
  overflow-x: hidden;
}

.MuiFormLabel-root {
  margin-bottom: 0;
  font-size: 14px;
}

.MuiFormLabel-root.Mui-focused {
  color: #346ba9;
}

.MuiFormControlLabel-root {
  margin-bottom: 0;
  padding-left: 0;
}

.MuiFormControlLabel-labelPlacementTop .MuiTypography-root {
  color: rgba(0, 0, 0, 0.6);
  font-size: 14px;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-weight: 400;
  line-height: 1;
  letter-spacing: 0.00938em;
  transition: color 200ms cubic-bezier(0, 0, 0.2, 1) 0ms, transform 200ms cubic-bezier(0, 0, 0.2, 1) 0ms;
  transform: translate(0, 0) scale(0.8);
  transform-origin: top center;
}

.MuiInputLabel-formControl,
.MuiInputLabel-shrink {
  white-space: nowrap;
}

.MuiInputLabel-formControl.leftOffset {
  left: 5px;
}

.MuiInput-underline:before {
  border-radius: 3px;
}

.MuiInput-underline:after {
  border-color: #346ba9;
  border-radius: 3px;
}

.MuiFilledInput-input {
  padding: 20px 0 10px;
}

.MuiFilledInput-underline:before {
  border-radius: 3px;
}

.MuiFilledInput-underline:after {
  border-color: #346ba9;
  border-radius: 3px;
}

.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
  border-color: #346ba9;
}

.MuiMenuItem-root {
  font-size: 14px;
}

.MuiListItem-root .selLabel,
.MuiListItem-root .selValue {
  display: inline-block;
}

.MuiAutocomplete-popper * {
  font-size: 14px;
}

.MuiButton-containedPrimary {
  background-color: #1d3e75;
  color: #fff;
}

.MuiButton-containedPrimary:hover {
  background-color: #346ba9;
  color: #fff;
}

.MuiTypography-body1 {
  font-size: 14px;
}

/* Rules for sizing the icon. */
.material-icons.md-18 {
  font-size: 18px;
}

.material-icons.md-24 {
  font-size: 24px;
}

.material-icons.md-36 {
  font-size: 36px;
}

.material-icons.md-48 {
  font-size: 48px;
}

/* Rules for using icons as black on a light background. */
.material-icons.md-dark {
  color: rgba(0, 0, 0, 0.54);
}

.material-icons.md-dark.md-inactive {
  color: rgba(0, 0, 0, 0.26);
}

/* Rules for using icons as white on a dark background. */
.material-icons.md-light {
  color: white;
}

.material-icons.md-light.md-inactive {
  color: rgba(255, 255, 255, 0.3);
}

/***** Wrapper *****/
.App {
  display: block;
  min-width: 905px;
  margin: 0;
  padding: 0;
  color: #212529;
  text-align: center;
}

.App .header {
  margin-top: 10px;
}

.App hr {
  margin: 0;
  border: 0;
  border-top: 1px inset rgba(233, 236, 239, 0.3);
  border-top: 2px outset rgba(233, 236, 239, 0.3);
  width: 100%;
}

.App .bg-primary {
  background-color: #014189 !important;
}

.App .bg-secondary {
  background-color: #346ba9 !important;
}

.App .border-primary {
  border-color: #1d3e75 !important;
}

.App .align-right {
  text-align: right;
}

.App .hidden {
  display: none;
}

.App .unselectable {
  -moz-user-select: none;
  -webkit-user-select: none;
}

.App .full-width {
  width: 100%;
}

.App .jumbotron {
  background-color: #f3f3f3;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.6);
}

@media (min-width: 576px) {
  .App .jumbotron {
    padding: 1.5rem 0;
  }
}

.App-content {
  display: flex;
  flex-wrap: nowrap;
  width: 100%;
}

.App-content .watermark-overlay {
  position: fixed;
  top: 0;
  left: 0;
  z-index: -1;
  width: 100%;
  min-height: 115vh;
  background-color: rgba(233, 236, 239, 0.25);
}

.App-viewarea {
  display: block;
  position: relative;
  width: 70%;
  min-width: 650px;
  min-height: 100vh;
  overflow: hidden;
  transition: all 300ms;
}

.App-viewarea .watermark {
  position: absolute;
  top: -55px;
  left: 0;
  z-index: -1;
}

.App-viewarea .watermark img {
  width: 100%;
  min-width: 650px;
  padding: 5px 18px;
  opacity: 0.1;
}

.App-viewarea form ::-webkit-input-placeholder {
  font-style: italic;
}

.App-viewarea form :-ms-input-placeholder {
  font-style: italic;
}

.App-viewarea form ::-ms-input-placeholder {
  font-style: italic;
}

.App-viewarea form ::placeholder {
  font-style: italic;
}

.App-viewarea form .form-row .form-row {
  margin-left: 0;
  margin-right: 0;
}

.App-viewarea form .form-row .form-row.col, .App-viewarea form .form-row .form-row[class*=col-] {
  padding-left: 0;
  padding-right: 0;
}

.App-viewarea form .form-row .form-row hr {
  border-top-width: 2px;
}

.App-viewarea form .form-row .col,
.App-viewarea form .form-row [class*=col-] {
  padding-right: 0;
  padding-left: 0;
}

.App-viewarea form .form-row > .col,
.App-viewarea form .form-row > [class*=col-] {
  padding-left: 5px;
  padding-right: 5px;
}

.App-viewarea form .form-row .form-group.inline {
  width: 100%;
  display: flex;
  flex-wrap: nowrap;
  box-shadow: 0px 4px 8px -4px rgba(0, 0, 0, 0.6);
  border-radius: 4px;
}

.App-viewarea form .form-row .form-group.inline label {
  vertical-align: bottom;
  white-space: nowrap;
  margin-bottom: 0;
  padding: 0.375rem 0.75rem;
  text-align: left;
}

.App-viewarea form .form-row .form-control {
  border: none;
  border-bottom: 1px solid #ced4da;
}

.App-viewarea form .form-row .row-control {
  margin: auto 0;
  line-height: 2.5;
}

.App-viewarea form .form-row .row-control .item-icon {
  font-size: 16px;
}

.App-viewarea form .form-row .row-control .item-btn {
  padding: 1px 5px;
  border-color: #777777;
  color: #777777;
}

.App-viewarea form .form-row .row-control .item-btn:hover {
  color: #495057;
  border-color: #495057;
}

.App-viewarea form .form-row .subform {
  margin: 24px 0;
}

.App-viewarea form .form-row .subform hr {
  margin: 15px 0 0 0;
}

.App-viewarea form .form-row [id$=-selector] label + .MuiInput-formControl {
  margin-top: 14px;
}

body #s4-mini-header {
  display: none;
}
/*# sourceMappingURL=styles.css.map */
