.MuiAutocomplete-popper [class*="selOffice-selValue"] {
  min-width: 30px;
  max-width: 30px;
}

// .MuiAutocomplete-popper [class*="selOffice-sellabel"] {
//   min-width: 150px;
//   width: 150px;
//   white-space: nowrap;
// }

.MuiSelect-root [class*="selOffice-selLabel"] {
  display: none;
}

#officeSelected label+.MuiInput-formControl {
  margin-top: 16px;
}

#officeSelected-label.altLeftOffset {
  left: -10px;
}