@import "./_variables.scss";

.App-viewarea {
    #pgStaffTDY {
        .btn-add-tdy {
            margin: auto 0;
            white-space: nowrap;
        }

        hr {
            border-bottom: 2px inset $secondary-color;
        }
    }
}