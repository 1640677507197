@import "./_variables.scss";

.MuiDialog-paperWidthMd {
    width: 960px;
}

.App-viewarea {
    #pgStaffTDY {
        .MuiIconButton-root {
            color: $secondary-color;
        }

        .MuiCollapse-container {
            width: 100%;
        }

        .MuiCollapse-wrapper {
            width: 100%;
        }

        .row-control {
            margin-top: 16px;
        }

        // .row-control .item-btn {
        //     border-color: transparent;
        // }

        // .row-control .item-icon {
        //     font-size: 14px;
        // }

        @media (min-width: 1200px) {
            .col-xl-3 {
                flex: 0 0 24%;
            }
        }
    }
}