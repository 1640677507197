@import "./_variables.scss";

#dependentsGrid {
  padding: 0;

  .MuiTable-root {
    background-color: $smoke-bg;
  }

  .MuiTableRow-root {
    white-space: nowrap;
  }

  .MuiTableCell-head {
    background-color: rgba(0, 0, 0, 0.09);
    color: rgba(0, 0, 0, 0.54);
  }

  .MuiTableCell-body {
    color: rgba(0, 0, 0, 0.54);
  }
}