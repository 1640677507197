@import './_variables.scss';

.MuiMenuItem-root [class*="selTravel-selValue"] {
    min-width: 20px;
    max-width: 20px;
}

.MuiSelect-root [class*="selTravel-selValue"] {
    display: none;
}

#travel-selector label+.MuiInput-formControl {
    margin-top: 14px;
}