.MuiAutocomplete-popper [class*="selJob-selValue"] {
    min-width: 110px;
    max-width: 120px;
}

.MuiSelect-root [class*="selJob-selLabel"] {
    display: none;
}

#job-selector label+.MuiInput-formControl {
    margin-top: 14px;
}